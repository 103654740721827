import { Module, VuexModule, getModule } from 'vuex-module-decorators';
import store from '@/store';
import CbxVariables from '@/models/CbxVariables';

@Module({
    store,
    dynamic: true,
    name: 'cbxvariables',
    namespaced: true,
})
class CbxVariablesClass extends VuexModule {
    private variables: CbxVariables = {
        testSiteEnabled: (window as any).CbxTestSiteEnabled,
        allowPurchase: (window as any).CbxAllowPurchase,
        showPrices: (window as any).CbxShowPrices,
        apiContextKey: (window as any).CbxApiContextKey,
        checkoutpage: (window as any).CbxCheckoutpage,
        checkoutpageNext: (window as any).CbxCheckoutpageNext,
        culture: (window as any).CbxCulture,
        currency: (window as any).CbxCurrency,
        signinstatus: (window as any).CbxSignInStatus,
        userrole: (window as any).CbxUserRole,
        currentCategoryId: (window as any).CbxCurrentCategoryId,
        currentCategoryPath: (window as any).CbxCurrentCategoryPath,
        currentContentParentId: (window as any).CbxCurrentContentParentId,
        currentProductId: (window as any).CbxCurrentProductId,
        dashboardUrl: (window as any).CbxDashboardUrl,
        enableAddedToCartModal: (window as any).CbxEnableAddedToCartModal,
        showCategoriesInMainNavigation: (window as any).CbxShowCategoriesInMainNavigation,
        enableCustomMainNavigtaion: (window as any).CbxEnableCustomMainNavigtaion,
        enablePurchaseFromLists: (window as any).CbxEnablePurchaseFromLists,
        enableVouchers: (window as any).CbxEnableVouchers,
        gmapsAPIKey: (window as any).CbxGmapsAPIKey,
        homepage: (window as any).CbxHomepage,
        loginpage: (window as any).CbxLoginPageUrl,
        logoImage: (window as any).CbxLogoImage,
        enableSecondaryImage: (window as any).CbxEnableSecondaryImage,
        placeholderImage: (window as any).CbxPlaceholderImage,
        productStockSettings: (window as any).CbxProductStockSettings,
        productListOptions: (window as any).CbxProductListOptions,
        rootCategoryId: (window as any).CbxRootCategoryId,
        rootContentId: (window as any).CbxRootContentId,
        searchPageUrl: (window as any).CbxSearchPageUrl,
        topcategoryUrl: (window as any).CbxTopcategoryUrl,
        filterFields: (window as any).CbxFilterFields,
        variantSeparator: (window as any).CbxVariantSeparator,
        variantDisplayOptions: (window as any).CbxVariantDisplayOptions,
        enableMagicSearch: (window as any).CbxEnableMagicSearch,
        bulkOrderingReversed: (window as any).CbxBulkOrderingReversed,
        hideLogin: (window as any).CbxHideLogin,
        enableWishList: (window as any).CbxEnableWishList,
        enableBestSellers: (window as any).CbxEnableBestSellers,
        enableNewsletterSubscription: (window as any).CbxEnableNewsletterSubscription,
        productspage: (window as any).CbxProductsPageUrl,
        enableCustomerProfiles: (window as any).CbxEnableCustomerProfiles,
        customerProfile: (window as any).CbxCustomerProfile,
        includeDescendantCategories: (window as any).CbxIncludeDescendantCategories,
        disableCatalogue: (window as any).CbxDisableCatalogue,
        contextCacheKey: (window as any).CbxContextCacheKey,
        enableCsvImport: (window as any).CbxEnableCsvImport,
        enableEventTracking: (window as any).CbxEnableEventTracking,
        enableFreeGifts: (window as any).CbxEnableFreeGifts,
        showNewsletterSubscriptionInCheckout: (window as any).CbxShowNewsletterSubscriptionInCheckout ?? false,
        storeInformation: (window as any).CbxStoreInformation,
        customerIsBlocked: (window as any).CbxCustomerIsBlocked,
        enableVatNumberValidation: (window as any).CbxEnableVatNumberValidation,
    };

    get VARIABLES(): CbxVariables {
        return this.variables;
    }
}

export const CbxVariablesModule = getModule(CbxVariablesClass);
